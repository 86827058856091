<template>
  <b-card class="page-card" body-class="px-0">
    <v-wait for="loadingSelectedReport">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="15"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div>
        <div class="row py-3">
          <div class="col-sm-8 pl-1">
            <h5 class="modal-title mb-0" v-if="keyMetricReport">
              Key Metric Report
              <span class="p-0 pl-1 pb-1  favorite__star__selected"
                ><b> &#x2605;</b>
              </span>
            </h5>
          </div>
          <div class="col-sm-4" v-if="!isMortgageLender">
            <div
              class="row align-items-baseline text-right"
              v-if="!keyMetricReport"
            >
              <div class="col-sm-4 font-weight-bold">
                FI Type :
              </div>
              <div class="col-sm-8 report-output-view pl-0 pr-1">
                <Multiselect
                  track-by="value"
                  label="text"
                  v-model="fiType"
                  :allowEmpty="false"
                  :showLabels="false"
                  :options="fiTypeOptions"
                  class="rw-multi-select aq-multiselect"
                ></Multiselect>
                <div
                  class="invalid-feedback d-block info-msg text-left"
                  v-if="showWarning"
                >
                  <span class="fa fa-info-circle pr-1"></span>
                  {{ this.actualFIType | formatFIType }} is the actual FI Type
                </div>
              </div>
            </div>
          </div>
        </div>
        <custom-report
          :elements="reportElements"
          :showOutput="showOutput"
          :selectedReport="selectedReport"
          rendered-in="FIN Query"
          ref="customReport"
        ></custom-report>
        <div class="row">
          <div class="col-sm-9 col-sm-9-mw-70">
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12 pb-1">
                    <b-form-group>
                      <label for="Search" class="mb-1">
                        <b>Report Name</b><i class="pl-1">(required on save)</i>
                      </label>
                      <b-form-input
                        type="text"
                        name="report_name"
                        class="form-control rounded"
                        placeholder="Enter a Report Name"
                        v-model="reportName"
                        ref="reportName"
                        :class="[
                          $v.reportName.$dirty && $v.reportName.$invalid
                            ? 'is-invalid'
                            : '',
                          'form-control rounded'
                        ]"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        v-if="$v.reportName.$dirty && $v.reportName.$invalid"
                        >report name required to save</b-form-invalid-feedback
                      >
                      <div
                        class="invalid-feedback d-block info-msg"
                        v-if="showEditWarning"
                      >
                        <span class="fa fa-info-circle pr-1">
                          changing name will save this as new report
                        </span>
                      </div>
                      <div
                        class="invalid-feedback d-block info-msg"
                        v-else-if="hasUnsavedChanges"
                      >
                        <span class="fa fa-info-circle pr-1"></span>
                        changes not saved
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Description</b><i class="pl-1">(Optional)</i>
                  </label>
                  <b-form-textarea
                    id="description"
                    class="form-control rounded"
                    v-model="reportDescription"
                    rows="2"
                    max-rows="2"
                  >
                  </b-form-textarea>
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6">
                <b-form-group>
                  <label for="Search" class="mb-1">
                    <b>Group Access</b><i class="pl-1">(Optional)</i>
                  </label>

                  <multiselect
                    v-model="selectedGroups"
                    :options="userGroups"
                    :multiple="true"
                    :close-on-select="false"
                    label="name"
                    track-by="id"
                    :showLabels="false"
                    placeholder
                    class="simple-select"
                    :searchable="false"
                  >
                    <template slot="selection" slot-scope="{ values }"
                      >{{ values.length }}
                      {{ values.length | pluralize('group') }}
                      selected</template
                    >
                    <template slot="option" slot-scope="group">
                      <div>
                        <input
                          type="checkbox"
                          :checked="isGroupSelected(group.option)"
                          class="mr-1"
                        />
                        {{ group.option.name }}
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-9 d-flex justify-content-center">
            <div class="px-1" v-if="userLoggedIn">
              <button
                type="button"
                class="btn fw-btn btn-success rounded"
                v-b-modal.customReportsList
              >
                Load Report
              </button>
            </div>
            <div class="px-1 d-flex flex-column" v-if="userLoggedIn">
              <div>
                <button
                  type="button"
                  :class="[
                    'btn fw-btn btn-success rounded',
                    showError ? 'field-error' : ''
                  ]"
                  @click.stop="saveReport()"
                >
                  Save Report
                </button>
              </div>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn fw-btn btn-secondary rounded"
                @click="resetReport"
              >
                Reset Report
              </button>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn fw-btn btn-primary rounded"
                @click="executeReport"
                :disabled="isProcessingData"
              >
                Run Report
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
    <custom-reports-modal
      :isMbc="isMortgageLender"
      @reportLoaded="reportLoaded"
    >
    </custom-reports-modal>
  </b-card>
</template>

<script>
//global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
//ui components
import CustomReport from '@/modules/report_writer/components/CustomReport'
import CustomReportsModal from '../components/CustomReportsModal'
import Multiselect from 'vue-multiselect'
//validations
import { required } from 'vuelidate/lib/validators'
//utilities
import _ from 'lodash'
import deepClone from '@/utilities.js'
import { areReporterElementsModified } from '../helpers/utilities'
//module utilities
import { formattedQueryElementsToCopyToReporter } from '../helpers/utilities.js'

export default {
  name: 'AQReportWriter',
  components: {
    Multiselect,
    CustomReport,
    CustomReportsModal
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prepareReportWriter()
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.showAlert(to.name, to.query)) {
      this.$dialog
        .confirm(
          {
            title: 'Alert',
            body:
              'Report Elements are not updated. Would you like to run it now?'
          },
          {
            okText: 'Run Report',
            cancelText: 'No'
          }
        )
        .then(() => {
          this.executeReport()
        })
        .catch(() => {
          this.prepareExit()
          next()
        })
    } else {
      next()
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('ReportWriter', {
      showOutput: state => state.showOutput,
      selectedReport: state => state.selectedReport,
      reportElements: state => state.reportElements,
      savedReportElements: state => state.savedReportElements,
      actualFIType: state => state.actualFIType,
      reportNameAsInDB: state => state.selectedReportNameAsInDB,
      elementsAddedInPricing: state => state.elementsAddedInPricing
    }),
    ...mapState('AdvancedQuery', {
      isValidQuery: state => state.isValidQuery,
      fiTypeBasedOnQuery: state => state.fiType,
      fiTypeOptions: state => state.fiTypeOptions,
      selectedQuery: state => state.selectedQuery,
      fisWOCExcluded: state => state.fisWOCExcluded
    }),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('AdvancedQuery', ['isProcessingData', 'isMortgageLender']),
    ...mapFields('ReportWriter', {
      reportName: 'selectedReport.name',
      reportGroupIDs: 'selectedReport.group_ids',
      reportDescription: 'selectedReport.description',
      reportCategory: 'selectedReport.category'
    }),
    fiType: {
      get() {
        return this.fiTypeOptions.find(
          ft => ft.value === this.selectedReport.fi_type
        )
      },
      set(type) {
        this.setReportFIType(type.value)
      }
    },
    selectedGroups: {
      get() {
        return this.userGroups.filter(ug => {
          return this.reportGroupIDs.includes(ug.id)
        })
      },
      set(groups) {
        this.setReportGroupIDs(groups.map(g => g.id))
      }
    },
    userLoggedIn() {
      return this.currentUserEmail
    },
    showError() {
      return this.$v.reportName.$dirty && this.$v.reportName.$invalid
    },
    showEditWarning() {
      return (
        this.selectedReport.id &&
        this.selectedReport.name.trim() !== this.reportNameAsInDB.trim()
      )
    },
    reportNameUnchanged() {
      return this.selectedReport.name.trim() === this.reportNameAsInDB.trim()
    },
    showWarning() {
      return (
        this.selectedReport.id &&
        this.selectedReport.fi_type !== this.actualFIType
      )
    },
    isEditMode() {
      return this.selectedReport.id
    },
    hasUnsavedChanges() {
      return (
        this.isEditMode &&
        !_.isEqual(this.reportElements, this.savedReportElements)
      )
    },
    keyMetricReport() {
      return this.reportCategory === 'key_metrics'
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', ['runReport', 'fetchSubscription']),
    ...mapMutations('ReportWriter', [
      'clearReportData',
      'setCustomReport',
      'setReportFIType',
      'setUserCustomReports',
      'setReportActualFIType',
      'setReportElements',
      'setReportGroupIDs',
      'setSavedReportElements',
      'setElementsAddedInPricing'
    ]),
    ...mapMutations('AdvancedQuery', ['setFIContactCount']),
    prepareReportWriter() {
      this.$refs.customReport.reloadActiveSegment()
    },
    resetReport() {
      this.clearReportData()
      this.setFIContactCount(null)
      this.setReportFIType(this.fiTypeBasedOnQuery)
      this.$v.$reset()
      this.$nextTick(() => {
        this.fetchSubscription()
        this.prepareReportWriter()
      })
    },
    executeReport() {
      this.runReport().then(
        () => {
          this.$router.push('download')
        },
        () => {
          if (this.isValidQuery) {
            this.$router.push('download')
          } else {
            this.$router.push({ path: 'query', query: { validate: true } })
          }
        }
      )
    },
    saveReport() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.selectedReport.id && this.reportNameUnchanged
          ? this.updateReport()
          : this.createReport()
      }
    },
    createReport() {
      let customElements = [
        ...formattedQueryElementsToCopyToReporter(this.isMortgageLender),
        ...this.reportElements
      ]
      selfServeReportsAPI
        .create(
          this.reportName,
          this.reportDescription,
          this.reportGroupIDs,
          this.isMortgageLender ? 'MBC' : this.fiType.value,
          this.isMortgageLender,
          customElements
        )
        .then(res => {
          if (res) {
            this.setUserCustomReports([])
            this.setCustomReport(res.report)
            this.setReportElements(deepClone(customElements))
            this.setReportActualFIType(res.report.fi_type)
            this.setSavedReportElements(deepClone(customElements))
            this.$toasted.global.action_success('Report created successfully.')
            this.fetchSubscription()
          }
        })
    },
    updateReport() {
      let customElements = [
        ...formattedQueryElementsToCopyToReporter(this.isMortgageLender),
        ...this.reportElements
      ]

      selfServeReportsAPI
        .update(
          this.selectedReport.id,
          this.reportName,
          this.reportDescription,
          this.reportGroupIDs,
          this.isMortgageLender ? 'MBC' : this.fiType.value,
          this.isMortgageLender,
          customElements
        )
        .then(res => {
          this.setUserCustomReports([])
          this.setCustomReport(res.report)
          this.setReportElements(deepClone(customElements))
          this.setReportActualFIType(res.report.fi_type)
          this.setSavedReportElements(deepClone(customElements))
          this.$toasted.global.action_success('Report updated successfully.')
        })
    },
    hasUnsavedElements() {
      return (
        areReporterElementsModified(
          this.reportElements,
          this.elementsAddedInPricing
        ) || this.contactsChanged()
      )
    },
    routingWithinFINQuery(toPath) {
      return ['AQQueryBuilder', 'AQDownload'].includes(toPath)
    },
    showAlert(toPath, toQuery) {
      if (toQuery && toQuery.validate) {
        return false
      }
      return this.routingWithinFINQuery(toPath) && this.hasUnsavedElements()
    },
    reportLoaded() {
      this.fetchSubscription(this.selectedQuery.id, this.selectedReport.id)

      this.$nextTick(() => {
        this.$refs.customReport.$refs.FIContacts[0].updateContactCount()
        if (this.$refs.customReport.$refs.MortgageLending)
          this.$refs.customReport.$refs.MortgageLending[0].updateMlElementList()
      })
    },
    prepareExit() {
      this.setElementsAddedInPricing(this.reportElements)
    },
    contactsChanged() {
      let contactElement = this.reportElements.find(
        re => re.type === 'fi_contacts'
      )
      let fisExcluded = contactElement ? contactElement.excludeFI : false
      return this.fisWOCExcluded !== fisExcluded
    },
    isGroupSelected(option) {
      return this.reportGroupIDs.includes(option.id)
    }
  },
  validations: {
    reportName: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.25rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
</style>

<style lang="scss">
.aq-multiselect {
  .multiselect__tags {
    border-color: #c2cfd6;
    padding-right: 30px;
  }
}
</style>
