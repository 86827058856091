<template>
  <b-modal
    id="customReportsList"
    title="Select a report to load"
    size="xl"
    hide-footer
    scrollable
    @show="loadInitData()"
  >
    <v-wait for="loadingReports">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="15"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <!-- <div class="col-sm-9"></div> -->
        <div class="col-sm-3 mb-3 align-right">
          <b-input-group>
            <b-form-input
              type="text"
              v-model="filter"
              placeholder="Search"
              ref="search"
            ></b-form-input>
            <div class="input-group-prepend">
              <button type="button" class="btn btn-secondary">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </b-input-group>
        </div>
      </div>
      <b-table
        striped
        hover
        small
        :items="customReports"
        :fields="tableFields"
        :class="{ processing: processingData }"
        :show-empty="true"
        :filter="filter"
        :filter-included-fields="getFilterableFields"
        :current-page="currentPage"
        :per-page="perPage"
        @filtered="onFiltered"
        responsive="md"
        empty-text="No records found."
      >
        <template v-slot:cell(name)="data">
          <div class="d-flex align-items-baseline">
            {{ data.item.name }}
            <span class="text-muted" v-if="isFinApiEnterpriseUser"
              >[{{ data.item.id }}]</span
            >
            <span
              class=" pl-1 favorite__star__selected"
              v-if="data.item.category === 'key_metrics'"
              ><b> &#x2605;</b></span
            >
          </div>
        </template>
        <!-- <template v-slot:cell(fi_type)="data">
          <div>
            {{ data.item.fi_type | formatFIType }}
          </div>
        </template> -->
        <template v-slot:cell(owner)="data">
          <div v-if="data.item.group_ids && data.item.group_ids.length">
            <h5>
              <b-badge pill variant="secondary" class="px-1">Shared</b-badge>
            </h5>
          </div>
          <div class="px-1" v-else>
            {{ data.item.first_name }} {{ data.item.last_name }}
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <div class="text-center">
            <button
              class="btn btn-primary btn-sm rounded mr-1"
              @click="loadReport(data.item)"
            >
              Load
            </button>
            <button
              class="btn btn-secondary btn-sm rounded ml-1"
              @click="deleteReport(data.item)"
            >
              Delete
            </button>
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col md="6">
          <b-pagination
            :total-rows="getLength"
            :per-page="perPage"
            v-model="currentPage"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </v-wait>
  </b-modal>
</template>

<script>
//global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'

export default {
  name: 'AQCustomReportsModal',
  props: {
    renderedIn: {
      type: String,
      required: false,
      default: 'Advanced Query'
    },
    fiType: {
      type: String,
      required: false,
      default: 'both'
    },
    isMbc: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      perPage: 15,
      totalRows: 1,
      filter: null,
      currentPage: 1,
      filteredDataLength: 0,
      processingData: false,
      tableFields: [
        { key: 'name', label: 'Name', sortable: true },
        {
          key: 'fi_type',
          label: 'FI Type',
          sortable: true,
          filterByFormatted: true,
          formatter: value => {
            return Vue.filter('formatFIType')(value)
          }
        },
        { key: 'description', label: 'Description', sortable: false },
        { key: 'owner', label: 'Owner', sortable: false },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          class: 'text-center'
        }
      ],
      filterOn: ['name', 'fi_type', 'id', 'description']
    }
  },

  computed: {
    ...mapState('ReportWriter', {
      reportElements: state => state.reportElements,
      selectedReport: state => state.selectedReport,
      customReports: state => state.userCustomReports
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    ...mapGetters('Ability', ['canVendorFit', 'isFinApiEnterpriseUser']),

    getFilterableFields() {
      return this.isFinApiEnterpriseUser
        ? this.filterOn
        : ['name', 'fi_type', 'description']
    },
    getLength() {
      return this.filteredDataLength
    }
  },
  methods: {
    ...mapMutations('AdvancedQuery', ['setFIType']),
    ...mapMutations('ReportWriter', [
      'setUserCustomReports',
      'setCustomReport',
      'setReportElements',
      'setSavedReportElements',
      'removeDeletedReport',
      'setReportActualFIType',
      'clearReportData'
    ]),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.filteredDataLength = filteredItems.length
    },
    loadInitData() {
      this.filter = null
      this.currentPage = 1
      this.$wait.start('loadingReports')
      // Promise.all([this.loadUserReports(),this.loadKeyMetricReports]).then(()=>{this.$wait.end('loadingReports')})
      selfServeReportsAPI
        .userReports({ is_mbc: this.isMbc })
        .then(res => {
          let validReports = res.custom_reports
          if (this.renderedIn !== 'Advanced Query') {
            validReports = validReports.filter(cr => {
              return cr.fi_type === this.fiType
            })
          } else {
            validReports = res.custom_reports.filter(cr => cr.fi_type !== 'bhc')

            if (!this.canVendorFit) {
              validReports = validReports.filter(report => {
                return !(report.report_elements.elements || []).find(
                  el => el.element[0].element_type === 'vendor_fit_static'
                )
              })
            }
          }
          this.totalRows = validReports.length
          this.filteredDataLength = validReports.length
          this.setUserCustomReports(validReports)
        })
        .finally(() => {
          this.$wait.end('loadingReports')
        })
    },
    loadReport(report) {
      this.setCustomReport(report)
      this.setFIType(report.fi_type)
      this.setReportActualFIType(report.fi_type)
      this.$bvModal.hide('customReportsList')

      this.$wait.start('loadingSelectedReport')

      selfServeReportsAPI
        .edit(this.selectedReport.id)
        .then(() => {
          this.setSavedReportElements(this.reportElements)
        })
        .finally(() => {
          this.$wait.end('loadingSelectedReport')
          this.$emit('reportLoaded')
        })
    },
    deleteReport(report) {
      this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: 'Are you sure, you want to delete the report?'
          },
          {
            okText: 'Delete',
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          this.processingData = true

          selfServeReportsAPI.delete(report.id).then(() => {
            this.removeDeletedReport(report)

            if (this.selectedReport && this.selectedReport.id === report.id) {
              this.clearReportData()
            }

            this.$toasted.show('Report successfully deleted.', {
              icon: 'user-circle',
              type: 'success'
            })

            this.processingData = false
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 1px grayscale($color: #686262fb);
}
</style>
